.radial-gradient {
  /*Fallback if gradeints don't work */
  background: #020024;
  /*Linear gradient... */
  background: radial-gradient(at top right, #190a20, #020024);
}

.hideme {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 1s;
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

.logo:nth-child(0) {
  transition-delay: 0ms;
}

.logo:nth-child(2) {
  transition-delay: 400ms;
}

.logo:nth-child(3) {
  transition-delay: 600ms;
}
.logo:nth-child(4) {
  transition-delay: 800ms;
}

.logo:nth-child(5) {
  transition-delay: 1000ms;
}

.logo:nth-child(6) {
  transition-delay: 1200ms;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #d97706;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

html {
  overflow-x: hidden;
}
